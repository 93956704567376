import React from "react";
import { Link } from "react-router-dom";
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceAreaFive = () => {
  const handleError = ()=>{
    toast.error('Service still updating...')
}
  return (
    <>
    <ToastContainer />
      {/*=================== service area five start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-60 pd-bottom-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                
                <h2 className='title'>
                  Explore our services
                </h2>
              </div>
            </div>
          </div>
          <div className='row custom-no-gutter'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/facility.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/facilities'>Sports Facilities</Link>
                  </h5>
                  <p className='mb-0'>
                    Explore sports facilities from constituency to the national level
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/athletes2.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Sportsmen/Athletes</Link>
                  </h5>
                  <p className='mb-0'>
                    Retired,Inactive,active and their qualification including experience and success
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/coach.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Coaches</Link>
                  </h5>
                  <p className='mb-0'>
                  Retired,Inactive,active and their qualification including experience and success
                  </p>
                </div>
              </div>
              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/federations.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Federations/Unions</Link>
                  </h5>
                  <p className='mb-0'>
                  All registered and their activities, including members & international link
                  </p>
                </div>
              </div>
              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/sponsor.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Sponsors</Link>
                  </h5>
                  <p className='mb-0'>
                  All sponsors involved in supporting events i.e current and past
                  </p>
                </div>
              </div>
              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/institutions.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Government Sports Instituitions</Link>
                  </h5>
                  <p className='mb-0'>
                  All departments linked with running sports
                  </p>
                </div>
              </div>
              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/institutions.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Sports Instituitions</Link>
                  </h5>
                  <p className='mb-0'>
                  All tertiary institutions offering sports courses and their sports departments
                  </p>
                </div>
              </div>
              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/cbc.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>CBC Sports Instituitions</Link>
                  </h5>
                  <p className='mb-0'>
                  Primary and Secondary institutions with focus to sports
                  </p>
                </div>
              </div>
            </div>
            
            <div className='col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none'>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'space-evenly'}} className='service-thumb service-middle-section align-self-end'>
                <img src='assets/img/about/sportsbanner3.jpg' alt='img' />
                <img src='assets/img/about/sportsbanner2.jpg' alt='img' />
                {/* <img src='assets/img/about/22.png' alt='img' /> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/sportsmedia.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Sports Media Stations</Link>
                  </h5>
                  <p className='mb-0'>
                    Media stations with focus to broadcasting sports
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/sportsPartners.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>International Partners in sports</Link>
                  </h5>
                  <p className='mb-0'>
                    Linked with federations and unions
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/cbo.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Community Based Organisations In Sports</Link>
                  </h5>
                  <p className='mb-0'>
                    NGOs, CBOs using sports for sustainable development
                  </p>
                </div>
              </div>

              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/private.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Private Companies In Sports</Link>
                  </h5>
                  <p className='mb-0'>
                  All registered sports companies and their level of engagement
                  </p>
                </div>
              </div>

              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/onlineGaming.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Online Gaming</Link>
                  </h5>
                  <p className='mb-0'>
                  Gaming Organisations in Kenya
                  </p>
                </div>
              </div>

              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/careers.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Careers In Sports</Link>
                  </h5>
                  <p className='mb-0'>
                  Opportunities in sports from institutions
                  </p>
                </div>
              </div>

              <div style={{marginTop:'8%'}} className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/resource.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link onClick={handleError}>Sports Resource Center</Link>
                  </h5>
                  <p className='mb-0'>
                 
                  </p>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFive;
