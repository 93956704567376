import {React, useState,useEffetc, useEffect }from 'react'
import NavBar from '../components/NavBar'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader } from 'reactstrap';
import Table from 'react-bootstrap/Table';
import countiesData from './counties.json'; 
import './facilities.css'

<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"></link>


export default function Facilities() {

    const [counties, setCounties] = useState([]);
    const [subCounties, setSubcounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [selectedSubcounty, setSelectedsubcounty] = useState('')
    const [facilities, setFacilities] = useState([]);
    const [searchItem, setSearchItem] = useState('');
    const [modal, setModal] = useState(false);
    const [selectedFacilityname, setSelectedFacilityname] = useState('');
    const[thisFacility, setThisfacility] = useState('');
    const [about, setAbout] = useState('');
    const [profileImage, setProfileimage] = useState('');
    const [facilityEvents, setFacilityevents] = useState([]);
    const [bookFacility, setBookFacility] = useState(false);
    const [eventName, setEventname] = useState('');
    const [eventDescription, setEventdescription] = useState('');
    const [startDate, setStartdate] = useState('');
    const [endDate, setEndate] = useState('');
    const [inputError, setInputerror] = useState('');
    const [filterBy, setFilterby] = useState('');
    const [sport, setSport] = useState('');
    const [level,setLevel] = useState('')


    const toggle = (event) => {
        setModal(!modal);

        const facilityId = event.target.value;

        setThisfacility(facilityId);
        Axios.get(`https://dskserver.uidevlabs.com/api/getSpecificFacility?facilityID=${facilityId}`).then((res) =>{
            setSelectedFacilityname(res.data.facilityName)
            setAbout(res.data.about);
            setProfileimage(res.data.profileImage)
        })
      
      };

    const book = () =>{
        setBookFacility(true)
    }
    const viewFacility = () =>{
        setBookFacility(false)
    }


    useEffect(() =>{

        if(searchItem == ''){
            Axios.get('https://dskserver.uidevlabs.com/api/getAllFacilities').then((res) =>{
                setFacilities(res.data)
            })

        }
        else{
            Axios.get(`https://dskserver.uidevlabs.com/api/searchFacility?searchItem=${searchItem}`).then((res) =>{
                setFacilities(res.data)
            })
        }
       

    },[searchItem])



    useEffect(() =>{
        Axios.get(`https://dskserver.uidevlabs.com/api/facilityEvents?facilityName=${selectedFacilityname}`).then((res)=>{
            setFacilityevents(res.data)
        })
    },[selectedFacilityname])



    
    const submitEvent = () =>{
        if(eventDescription !== '' && eventName !== '' && startDate !== '' && endDate !== ''){
            Axios.post('https://dskserver.uidevlabs.com/api/postEvent',{
                facilityName: selectedFacilityname,
                event: eventName,
                eventDescription: eventDescription,
                startDate: startDate,
                endDate:endDate
            }).then((res) =>{
                setEventname('');
                setEventdescription('');
                setStartdate('');
                setEndate('');
                Axios.get(`https://dskserver.uidevlabs.com/api/facilityEvents?facilityName=${selectedFacilityname}`).then((res)=>{
                    setFacilityevents(res.data)
                })
                setBookFacility(false)
            }).catch((err) =>{
                setInputerror('Something wrong happenned. Please try again')
            })
            
        }
        else{
            setInputerror('All event fields are required')
        }
    }

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);
        const county = countiesData.find(c => c.name === countyName);
        setSubcounties(county ? county.sub_counties : []);
    };

    //Get facility by location
    useEffect(()=>{
        if(selectedCounty !=='' && selectedSubcounty !== ''){
            Axios.get(`https://dskserver.uidevlabs.com/api/getFacilitiesByLocation?county=${selectedCounty}&subcounty=${selectedSubcounty}`).then((res)=>{
                setFacilities(res.data)
            })
        }
       

    },[selectedCounty, selectedSubcounty])

    //Get facility by sport
    useEffect(()=>{
        if(sport !==''){
            Axios.get(`https://dskserver.uidevlabs.com/api/getFacilitiesBySport?sport=${sport}`).then((res)=>{
                setFacilities(res.data)
            })
        }
    },[sport])
    
    //Get facility by level
    useEffect(()=>{
        if(level !==''){
            Axios.get(`https://dskserver.uidevlabs.com/api/getFacilitiesByLevel?level=${level}`).then((res)=>{
                setFacilities(res.data)
            })
        }
    },[level])

        //Get facility by private institution and upcoming
    useEffect(()=>{
        if(filterBy =='Private institution'){
            Axios.get(`https://dskserver.uidevlabs.com/api/getFacilitiesByPrivate?filterby=${filterBy}`).then((res)=>{
                setFacilities(res.data)
            })
        }

        else if(filterBy =='Upcoming'){
            Axios.get(`https://dskserver.uidevlabs.com/api/getFacilitiesByUpcoming?filterby=${filterBy}`).then((res)=>{
                setFacilities(res.data)
            })
        }
    },[filterBy])




  return (
    <div  >
        <NavBar />
        <div className="facilitiesMain">
            <div className="facilitiesHeader">
                <h5>Sports Facilities({facilities.length})</h5>
               <div className='searchContainer'>
                <FloatingLabel className='filter' controlId="floatingInputGrid" label="Filter by">
                <Form.Select onChange={(e)=>{setFilterby(e.target.value)}} value={selectedCounty} className='inputSearch' aria-label="Floating label select example" >
                    <option>Filter by</option>
                    <option value="location">Location</option>
                    <option value="sport">Sport</option>
                    <option value="level">Facility level</option>
                    <option value="Private institution">Private instituition</option>
                    <option value="Upcoming">Upcoming</option>
                            
                </Form.Select>
         
                </FloatingLabel>

                <FloatingLabel className='search' controlId="floatingInputGrid" label="Search facility">
                    <Form.Control onChange={(e)=>{setSearchItem(e.target.value)}} className='inputSearch'  type="text" placeholder="Search facility" />
         
                </FloatingLabel>
                </div>
                
                
                {filterBy == 'location' &&

                <div className="filterDiv">
                <div className="filterCounty">
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="County"
                        >
                        <Form.Select onChange={handleCountyChange} value={selectedCounty} className='inputSearch' aria-label="Floating label select example" >
                            <option>County</option>
                            {countiesData.map((val) =>{
                                return(
                                    <>
                                        <option value={val.name}>{val.name}</option>
                                    
                                    </>
                                )
                            })}
                            
                        </Form.Select>
                    </FloatingLabel>
                </div>
                <div className="filterCounty">
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Sub-County"
                        >
                        <Form.Select onChange={(e)=>{setSelectedsubcounty(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                            <option>Sub-county</option>
                            {subCounties.map((subCounty) =>{
                                return(
                                    <>
                                    <option value={subCounty}>{subCounty}</option>
                                    </>
                                )
                            })}
                            
                        </Form.Select>
                    </FloatingLabel>
                </div>

                <div className="filterCounty">
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="Ward"
                        >
                        <Form.Select className='inputSearch' aria-label="Floating label select example" >
                            <option>Ward</option>
                            
                        </Form.Select>
                    </FloatingLabel>
                </div>
                </div>
                
                }

                {filterBy == 'sport' &&

                <div className="filterDiv">
                <div className="filterCounty">
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="County"
                        >
                        <Form.Select onChange={(e)=>{setSport(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                            <option value="Multipurpose">Multipurpose</option>
                            <option value="Football">Football</option>
                            <option value="Swimming">Swimming</option>
                            <option value="Volleyball">Volleyball</option>
                            <option value="Athletics">Athletics</option>
                            <option value="Rugby">Rugby</option>
                            <option value="Tennis">Tennis</option>
                            <option value="Cricket">Cricket</option>
                            <option value="Handball">Handball</option>
                            <option value="Hockey">Hockey</option>
                            <option value="Basketball">Basketball</option>
                            
                           
                            
                        </Form.Select>
                    </FloatingLabel>
                </div>
                </div>

                }

                {filterBy == 'level' &&

                <div className="filterDiv">
                <div className="filterCounty">
                    <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="County"
                        >
                        <Form.Select onChange={(e)=>{setLevel(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                            <option selected disabled>Facility level</option>
                            <option value="National">National</option>
                            <option value="County">County</option>
                            <option value="Municipal">Municipal</option>
                            <option value="Community">Community</option>
                    
                        </Form.Select>
                    </FloatingLabel>
                </div>
                </div>

                }



                {facilities.length != 0 &&
                <div className="facilitiesTable">


                
                {facilities.map((val) =>{
                    return(
                        <div className="facility">
                            <img className='stadium' src={`/assets/img/stadiums/${val.profileImage}`} alt="" />
                            <button onClick={toggle} value={val.id}  className='facilityTitle'>{val.facilityName}</button>
                            <p className='facilityLocation'>{val.county}, {val.subcounty}</p>
                            <p className='facilityCapacity'>Cap: {val.capacity}</p>
                            <p className='facilityCapacity'>Facility: {val.category}</p>
                        </div>
                    )
                })}
                    
                </div>
                
                }
                {facilities.length == 0 &&
                
                <div className="facilitiesTable">
                    <p>No data.</p>
                </div>
                
                }
                
                
            </div>

        </div>

         {/***********Contact Add Box**************/}
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>{selectedFacilityname}</ModalHeader>
        <div className="facilityModal">
            <p className='modalTitle'>About {selectedFacilityname}</p>
            <p className='facilityAbout'>{about}</p>
             
             {bookFacility == false &&
                <Button onClick={book} className='bookButton' color='warning'>Book Facility</Button>
             }
            {bookFacility == true &&
                <Button onClick={viewFacility} className='bookButton' color='warning'>View Facility</Button>
             }
             
            
            {bookFacility == false &&
                        <div className="facilityDetails">
                        <div className="facilityImageDiv">
                            <img className='facilityImage' src={`/assets/img/stadiums/${profileImage}`} alt="" />
                        </div>
                        <div className="facilityEvents">
                            <p className='modalTitle'>Events in 2024</p>
                            {facilityEvents.length == 0 &&
                                <p>No Events.</p> 
                            }
                            {facilityEvents.length !== 0 && 
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>Event</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {facilityEvents.map((val,index) =>{
                                            return(
                                                <tr>
                                                    <td className='facilityAbout'>{index+1}</td>
                                                    <td className='facilityAbout'>{val.event}</td>
                                                    <td className='facilityAbout'>{val.startDate}</td>
                                                    {val.status == 'Happenned' && <td><span className="badge bg-danger">{val.status}</span></td>}
                                                    {val.status == 'Upcoming' && <td><span className="badge bg-success">{val.status}</span></td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            
                            }
                            
        
                        </div>
                    </div>
            
            }
            {bookFacility == true && 
            <div className='facilityBook'>
               <p>Book this facility</p>
               <p><span className="badge bg-danger">{inputError}</span></p>
               <div className="bookForm">
               <FloatingLabel
                controlId="floatingSelectGrid"
                label="Event Title"
                >
                 <Form.Control type="text" onChange={(e)=>{setEventname(e.target.value)}} />
                </FloatingLabel>

                <FloatingLabel
                controlId="floatingSelectGrid"
                label="Event Description"
                >
                 <Form.Control onChange={(e)=>{setEventdescription(e.target.value)}} className='facilityTextarea' as="textarea" rows='15' />
                </FloatingLabel>

                
                <FloatingLabel
                controlId="floatingSelectGrid"
                label="Start Date"
                >
                 <Form.Control onChange={(e)=>{setStartdate(e.target.value)}}  type="date" className='facilityInput'    />
                </FloatingLabel>
                
                <FloatingLabel
                controlId="floatingSelectGrid"
                label="End Date"
                >
                 <Form.Control onChange={(e)=>{setEndate(e.target.value)}} type="date" className='facilityInput' />
                </FloatingLabel>
               </div>

               <Button onClick={submitEvent} className='bookButton' color='success'>Book</Button>
            </div>
            }




        </div>
       
      </Modal>
      {/***********Contact Add Box End**************/}

   {/***********Contact Add Box**************/}
        
    </div>
  )
}
