import {React, useState, useEffect} from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader } from 'reactstrap';
import './entries.css'
import countiesData from './counties.json'; 
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Entries() {

    const[entryType, setEntrytype] = useState('facilities')
    const [subCounties, setSubcounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [selectedSubcounty, setSelectedsubcounty] = useState('');
    const [category, setCategory] = useState('');
    const [level, setLevel] = useState('')
    const [file, setFile] = useState(null);
    const [facilityName, setFacilityname] = useState('');
    const [capacity, setCapacity] = useState('')

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await Axios.post('https://dskserver.uidevlabs.com/api/upload', {
        formData,facilityName,capacity,selectedCounty,selectedSubcounty,category,level  
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);
        const county = countiesData.find(c => c.name === countyName);
        setSubcounties(county ? county.sub_counties : []);
    };

    const handleError = ()=>{
        toast.error('Server is off! Contact support')
    }


  return (
    <div className='entriesWrapper'>
        <ToastContainer />
        <div className="entryLeft">
            <img className='entryImage' src="https://img.freepik.com/premium-photo/3d-soccer-ball-with-kenya-country-flag-football-ball-with-kenya-flag_603823-1265.jpg" alt="" />
        </div>
        <div className="entryRight">
            <h4>Welcome to Digital Sports Kenya</h4>
            <h5>Data Entry Panel</h5>
            <hr />
            <FloatingLabel controlId="floatingInputGrid" label="Select entry" >
                <Form.Select aria-label="Floating label select example" onChange={(e)=>{setEntrytype(e.target.value)}} >
                    <option selected disabled>Select entry</option>
                    <option value="facilities">Facilities</option>
                    <option value="athletes">Sports athletes</option>
                    <option value="coaches">Coaches</option>
                    <option value="federations">Federations/Unions</option>
                    <option value="sponsors">Sponsors</option>
                    <option value="government">Government Sports Institutions</option>
                    <option value="instituitions">Sports Instituitions</option>
                    <option value="CBC">CBC Sports Instituitions</option>
                    <option value="media">Sports Media Stations</option>
                    <option value="partners">International Partners in sports</option>
                    <option value="cbo">Community Based Organisations in sports</option>
                    <option value="private">Private companies in sports</option>
                    <option value="gaming">Online gaming</option>
                    <option value="career">Career in sports</option>
                    <option value="resource">Sports Resource center</option>



                            
                </Form.Select>
         
                </FloatingLabel>
                <hr />
                {entryType == 'facilities' &&
                <>
                    <div className="entryForm">
                        <div className="formGroup">
                        <FloatingLabel onChange={(e)=>{setFacilityname(e.target.value)}} className='formInput' controlId="floatingInputGrid" label="Facility Name">
                            <Form.Control  className='inputSearch'  type="text" placeholder="Facility name" />
                        </FloatingLabel>
                        <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Capacity">
                            <Form.Control onChange={(e)=>{setCapacity(e.target.value)}}  className='inputSearch'  type="text" placeholder="Capacity" />
                        </FloatingLabel>
                        </div>

                        <div className="formGroup">
                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="County"
                                className='formInput'
                                >
                                <Form.Select onChange={handleCountyChange} value={selectedCounty} className='inputSearch' aria-label="Floating label select example" >
                                    <option>County</option>
                                    {countiesData.map((val) =>{
                                        return(
                                            <>
                                                <option value={val.name}>{val.name}</option>
                                            
                                            </>
                                        )
                                    })}
                                    
                                </Form.Select>
                            </FloatingLabel>


                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="Sub-County"
                                className='formInput'
                                >
                                <Form.Select onChange={(e)=>{setSelectedsubcounty(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                                    <option>Sub-county</option>
                                    {subCounties.map((subCounty) =>{
                                        return(
                                            <>
                                            <option value={subCounty}>{subCounty}</option>
                                            </>
                                        )
                                    })}
                                    
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className="formGroup">
                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="Facility category"
                                className='formInput'
                                >
                                <Form.Select onChange={(e)=>{setCategory(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                                    <option value="Multipurpose">Multipurpose</option>
                                    <option value="Football">Football</option>
                                    <option value="Swimming">Swimming</option>
                                    <option value="Volleyball">Volleyball</option>
                                    <option value="Athletics">Athletics</option>
                                    <option value="Rugby">Rugby</option>
                                    <option value="Tennis">Tennis</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Handball">Handball</option>
                                    <option value="Hockey">Hockey</option>
                                    <option value="Basketball">Basketball</option>
                                    
                                
                                    
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="Facility Level"
                                className='formInput'
                                >
                                <Form.Select onChange={(e)=>{setLevel(e.target.value)}} className='inputSearch' aria-label="Floating label select example" >
                                    <option selected disabled>Facility level</option>
                                    <option value="National">National</option>
                                    <option value="County">County</option>
                                    <option value="Municipal">Municipal</option>
                                    <option value="Community">Community</option>
                            
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className="formGroup">
                        <FloatingLabel
                                controlId="floatingSelectGrid"
                                label="Upload photo"
                                className='formInput'
                                >
                               <Form.Control onChange={handleFileChange}   className='inputSearch'  type="file" placeholder="Upload photo" />
                            </FloatingLabel>
                        </div>
                    </div>
                    <Button color='success' className='submitBtn' onClick={handleError}>Submit</Button>
                    </>
                
                }
                {entryType !== 'facilities' &&
                <p>Form not updated! Contact support</p>
                }
               


        </div>

    </div>
  )
}
